/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

//Styling
import {footer, footerSecondary, categoryNav, categoryTitle, categoryList, categoryItem} from './footer.module.scss'

const BRAND_NAME = 'LEARNING CURVE'

const FooterCategories = ({category}) => {
    return(
        <nav className={categoryNav}>
            <h3 className={` ${categoryTitle} h3`}>{category.title}</h3>
            <ul className={categoryList}>
                {category.links.map((link, index) => (
                    <li key={index}>
                        {
                            link.type == 'social' ?
                                <div className='d-flex justify-content-center'>
                                    {
                                        link.socials.map((val, index) => (
                                            <a href={val.url} className={`p-2 mx-1 text-white`} key={index}> 
                                                <img height={32} width={32} className='lazyload' loading={'lazy'} alt={`${val.type} icon`} data-src={val.icon}/>
                                                <noscript>
                                                    <img height={32} width={32} alt={`${val.type} icon`} loading={'lazy'} src={val.icon}/>
                                                </noscript>
                                            </a>
                                        ))
                                    }
                                </div>:
                                link.type == 'internal' ?
                                    <Link className='btn btn-link text-white text-small' to={link.link}>{link.title}</Link>:
                                    <Button href={link.link} variant='link' className='text-white text-small'>{link.title}</Button>
                        }
                    </li>
                ))}
            </ul>
        </nav>
    )
}

const CATEGORY_DATA = [
    {
        title: 'Why Learning Curve',
        links: [
            {
                title: 'Our Process',
                link: '/process',
                type: 'internal'
            },
            {
                title: 'About Us',
                link: '/about',
                type: 'internal'
            },
        ]
    },
    {
        title: 'Case Studies',
        links: [
            {
                title: 'Rush Digital',
                link: '/cases/rush-digital',
                type: 'internal'
            },
            {
                title: 'Cultureflow',
                link: '/cases/cultureflow',
                type: 'internal'
            },
            {
                title: 'Engage',
                link: '/cases/engage',
                type: 'internal'
            }
        ]
    },
    {
        title: 'Company',
        links: [
            {
                title: 'Contact us',
                link: '/contact',
                type: 'internal'
            },
            {
                title: 'hello@learningcurve.so',
                link: 'mailTo:hello@learningcurve.so',
                type: 'external'
            },
        ]
    }
]

class Footer extends React.Component {
    render () {
        let today = new Date()
        let year = today.getFullYear()

        return <footer className={footer}>
            <Container>
                <Row>
                    <Col as='section' lg={12}>
                        <Link to='/'><h2 className='fw-brand text-center mb-5 text-white' style={{fontSize:'1.5rem'}}>{BRAND_NAME}</h2></Link>
                    </Col>
                    {CATEGORY_DATA.map((category, index) => (
                        <Col xs={12} sm={4} lg={4} className='col-12 col-sm-4 col-lg-4' key={index} > 
                            <FooterCategories category={category}/>
                        </Col>
                    ))}
                </Row>
            </Container>
            <div className={footerSecondary}>
                <h2 className='h5 text-center'>© {year}, {BRAND_NAME}</h2>
            </div>
        </footer>
    }
}

export default Footer