import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

class Topbar extends React.Component {
    //constructor to set state and bind "this"
    constructor(props){
        super(props)
        this.state = {
            isNavBarTop: true
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        const windowTop = window.scrollY < 400
        windowTop ? this.setState({isNavBarTop: true}) : this.setState({isNavBarTop: false}) 
    }

    render() {
        return <header className={`header py-2 ${this.props.type && this.state.isNavBarTop ? '' : '--floating'}`}>
            <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
                <Link to='/'><h1 className={'h2 py-1 text-center text-sm-left'}>Learning Curve</h1></Link>
            </div>
            <nav className='col d-flex align-items-center justify-content-end p-0'>
            <ul className='nav d-none d-md-flex'>
                    <li className='nav-item'>
                        <Link className='btn btn-link' to='/cases'>
                            {/* Desktop */}
                            <span>Case Studies</span>
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='btn btn-link' to='/process'>
                            {/* Desktop */}
                            <span>Our Process</span>
                        </Link>
                    </li>

                </ul>
                {/* Desktop */}
                <span className='d-none d-sm-none d-md-block d-lg-block'>
                    <Link className='btn btn-primary py-1 px-3' to='/contact'>Talk with us</Link>
                </span>
                {/* Tablet */}
                <span className='d-none d-sm-block d-md-none'>
                    <Link className='btn btn-primary py-1 px-3' to='/contact'>Contact Us</Link>
                </span>
            </nav>
        </header>
    }
}

Topbar.propTypes = {
    type: PropTypes.string
}

export default Topbar