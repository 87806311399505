import React from 'react'
import PropTypes from 'prop-types'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

//styles
import {main} from './layout.module.scss'

//components
import Topbar from '../Topbar/topbar'
import Footer from '../Footer/footer'

class Layout extends React.Component {

    render() {
        return <>
            <Topbar type={this.props.type} />
            <main className={`${main} ${this.props.classes}`}>
                {this.props.children}                       
            </main>
            <Footer/>
            <noscript>
                <style>
                    {`
                        [data-sal|=fade] {
                            opacity: 1;
                        }

                        [data-sal|=slide],
                        [data-sal|=zoom] {
                            opacity: 1;
                            transform: none;
                        }

                        [data-sal|=flip] {
                            transform: none;
                        }
                        
                        .no-js img.lazyload {
                            display: none;
                          }
                    `}
                </style>
            </noscript>
        </>
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    type: PropTypes.string
}

export default Layout